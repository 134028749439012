import React, { useState, useEffect } from "react";
import { Row, Col, Button, Spinner, Alert } from "reactstrap";
import { useMutation } from "@apollo/client";
import { CREATE_POS } from "../../requests";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import moment from "moment";
const MIN_PRICE = 1;
const Cash = ({
  name,
  phone,
  amount,
  email,
  description,
  handleSuccess,
  handleChargedAmount,
  handleDisplay,
  reference,
  checkValidationSuper,
  formIsValid,
  firstName,
  lastName,
  price,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [visible, setVisible] = useState(false);

  const [createPos] = useMutation(CREATE_POS);

  // VALIDATION

  const validateEmail = (email) => {
    const re =
      // eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const onDismiss = () => setVisible(false);

  useEffect(() => {}, [formIsValid]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOrderLoading(true);

    // name: "Tolgay"
    //   surname: "Efe"
    //   phone_number: "+905444255032"
    //   email: "tolgay.efe.te@gmail.com"
    //   charged_amount: 10.5
    //   paid_method: "cash"
    //   reference: "North Park"
    //   note: "pike is so handsome"
    const posInput = {
      name: firstName,
      surname: lastName,
      phone_number: phone,
      email: email,
      charged_amount: amount,
      paid_method: "cash",
      reference: reference,
      note: description,
      created_at: moment().unix(),
    };
    createPos({
      variables: {
        input: posInput,
      },
    })
      .then(() => {
        setOrderLoading(false);
        handleSuccess(true);
        handleChargedAmount(amount);
      })
      .catch((err) => {
        setOrderLoading(false);
        setAlertMessage("Payment had a problem.");
        setVisible(true);
      });
  };

  const handleConfirm = () => {
    checkValidationSuper();
    if (!validateForm()) {
      return;
    }
    setIsReady(true);
    handleDisplay(false);
  };

  const validateForm = () => {
    let flag = true;
    parseInt(price);
    if (firstName.length === 0) {
      flag = false;
    }
    if (lastName.length === 0) {
      flag = false;
    }
    // if (!isPossiblePhoneNumber(phone)) {
    //   flag = false;
    // }
    // if (!validateEmail(email)) {
    //   flag = false;
    // }
    if (!price || parseInt(price) < MIN_PRICE) {
      flag = false;
    }
    return flag;
  };
  return (
    <Col md={12}>
      <Alert color="warning" isOpen={visible} toggle={onDismiss}>
        {alertMessage}
      </Alert>
      <Row className={isReady ? "displayNone" : "credit-card-container"}>
        <Col></Col>

        <Col md={12}>
          <Button className="SubmitButton" onClick={handleConfirm}>
            CONFIRM
          </Button>
        </Col>
      </Row>

      <Row className={!isReady ? "displayNone" : "confirm-modal"}>
        <Col md={12} style={{ color: "#fff" }}>
          {name ? (
            <p>
              <span className="span-bold">Name:</span> {name}
            </p>
          ) : (
            ""
          )}
          {amount ? (
            <p>
              <span className="span-bold">Price:</span> ${amount * 0.01}{" "}
            </p>
          ) : (
            ""
          )}
          {phone ? (
            <p>
              <span className="span-bold">Phone:</span> {phone}
            </p>
          ) : (
            ""
          )}
          {email ? (
            <p>
              <span className="span-bold">Email:</span> {email}
            </p>
          ) : (
            ""
          )}
          {description ? (
            <p>
              <span className="span-bold">Note:</span> {description}
            </p>
          ) : (
            ""
          )}
          <p></p>
          <Button className="btn-confirm" onClick={handleSubmit}>
            {orderLoading ? <Spinner color="light"> </Spinner> : "CONFIRM"}
          </Button>

          <Button
            className="btn-cancel"
            onClick={() => {
              setIsReady(false);
              handleDisplay(true);
            }}
          >
            CANCEL
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default Cash;
