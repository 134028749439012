import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import "./App.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CreditCard from "./components/CreditCard";
import Cash from "./components/Cash";

import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { CashIcon, CreditCardIcon } from "./components/Icons";

const MIN_PRICE = 1;

function App(props) {
  const [reference, setReference] = useState(props.href);
  const [success, setSuccess] = useState(false);
  const [chargedAmount, setChargedAmount] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [orderNote, setOrderNote] = useState("");
  const [price, setPrice] = useState();
  const [display, setDisplay] = useState(true);
  const [loggedIn, setLoggedIn] = useState();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  // if (checkLogin) {
  //   setLoggedIn(true);
  // } else {
  //   setLoggedIn(false);
  // }

  // VALIDATION

  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [priceValid, setPriceValid] = useState(true);
  const [rSelected, setRSelected] = useState(1);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userPin, setUserPin] = useState("");

  // const [formValid, setFormValid]

  // LOGIN

  let checkLogin;
  if (localStorage) {
    checkLogin = localStorage.getItem("login");
  }

  const handlePin = (e) => {
    setUserPin(e.target.value);
    if (e.target.value === "real2021") {
      localStorage.setItem("login", true);
      checkLogin = true;
    }
  };
  const onClickLogout = () => {
    localStorage.setItem("login", false);
    checkLogin = false;
  };

  const handleSuccess = (value) => {
    setSuccess(value);
  };
  const handleChargedAmount = (value) => {
    setChargedAmount(value / 100);
  };
  const handleDisplay = (value) => {
    setDisplay(value);
  };

  const validateEmail = (email) => {
    const re =
      // eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    if (firstName.length === 0) {
      setFirstNameValid(false);
    } else {
      setFirstNameValid(true);
    }
    if (lastName.length === 0) {
      setLastNameValid(false);
    } else {
      setLastNameValid(true);
    }
    if (!isPossiblePhoneNumber(phone)) {
      setPhoneValid(false);
    } else {
      setPhoneValid(true);
    }
    if (!validateEmail(email)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
    if (!price || parseInt(price) < MIN_PRICE) {
      setPriceValid(false);
    } else {
      setPriceValid(true);
    }
    if (rSelected === 2) {
      setEmailValid(true);
      setPriceValid(true);
    }
  };
  // let logged = false;
  // if (localStorage) {
  //   logged = localStorage.getItem("logged");
  //   if (logged) {
  //     setIsLoggedIn(true);
  //   } else {
  //     setIsLoggedIn(false);
  //   }
  // }
  return (
    <div className="App">
      {checkLogin === "true" ? (
        <>
          <Button onClick={onClickLogout} href="/" className="logout-btn">
            Logout
          </Button>
          <Container className={success ? "displayNone" : ""}>
            <Row>
              <Col
                md={12}
                className="text-center"
                style={{ marginBottom: "20px" }}
              >
                <a href="https://real.dog/">
                  <img
                    src="https://realdog-s3.s3-us-west-1.amazonaws.com/rdblogo.gif"
                    width="80"
                    height="80"
                    alt="logo"
                  />
                </a>
              </Col>
            </Row>
            <Row className={!display ? "displayNone" : "FormGroup"}>
              <Col md={{ offset: 3, size: 6 }}>
                <Row>
                  <Col>
                    <FormGroup>
                      <Input
                        required
                        value={firstName}
                        onChange={(e) => {
                          if (
                            firstName.length === 0 &&
                            e.target.value.length !== 1
                          ) {
                            setFirstNameValid(false);
                          } else {
                            setFirstNameValid(true);
                          }
                          setFirstName(e.target.value);
                        }}
                        placeholder="Name"
                        invalid={!firstNameValid}
                      />
                      <FormFeedback>Name is invalid</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Input
                        required
                        value={lastName}
                        onChange={(e) => {
                          if (
                            lastName.length === 0 &&
                            e.target.value.length !== 1
                          ) {
                            setLastNameValid(false);
                          } else {
                            setLastNameValid(true);
                          }
                          setLastName(e.target.value);
                        }}
                        placeholder="Surname"
                        invalid={!lastNameValid}
                      />
                      <FormFeedback>Surname is invalid</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        invalid={!phoneValid}
                        style={{
                          display: "none",
                        }}
                      />
                      <PhoneInput
                        placeholder="Enter Mobile Number"
                        name="number"
                        defaultCountry="US"
                        value={phone}
                        onChange={(value) => {
                          setPhone(value);
                        }}
                      />
                      <FormFeedback>Phone number is invalid</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          if (!validateEmail(email)) {
                            setEmailValid(false);
                          } else {
                            setEmailValid(true);
                          }
                          setEmail(e.target.value);
                        }}
                        placeholder="Email"
                        invalid={rSelected === 2 ? false : !emailValid}
                      />
                      <FormFeedback>Email is invalid</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputGroup style={{ marginTop: "10px" }}>
                        {" "}
                        <InputGroupAddon addonType="append">
                          <InputGroupText className="border-left-5">
                            $
                          </InputGroupText>
                        </InputGroupAddon>{" "}
                        <Input
                          required
                          type="number"
                          step=".01"
                          value={price}
                          onChange={(e) => {
                            setPrice(e.target.value);
                          }}
                          placeholder="Price"
                          className="price-input"
                          style={{ marginTop: "0px" }}
                          invalid={!priceValid}
                        />
                        <FormFeedback>Amount of money is invalid</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Input
                      value={orderNote}
                      type="textarea"
                      onChange={(e) => setOrderNote(e.target.value)}
                      placeholder="Note"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label style={{ color: "#fff", marginLeft: 2 }}>
                      {" "}
                      Pay with:
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className={rSelected === 1 ? "btn-confirm" : ""}
                      style={{ width: "100%" }}
                      onClick={() => setRSelected(1)}
                      active={rSelected === 1}
                    >
                      <CreditCardIcon /> Credit Card
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className={rSelected === 2 ? "btn-confirm" : ""}
                      style={{ width: "100%" }}
                      onClick={() => setRSelected(2)}
                      active={rSelected === 2}
                    >
                      <CashIcon /> Cash
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              {rSelected === 1 && (
                <Col md={{ offset: 3, size: 6 }}>
                  <Row style={{ marginTop: "30px" }}>
                    <Elements stripe={stripePromise}>
                      <CreditCard
                        name={`${firstName}  ${lastName}`}
                        description={orderNote}
                        phone={phone}
                        email={email}
                        amount={price * 100}
                        firstName={firstName}
                        lastName={lastName}
                        price={price}
                        handleSuccess={handleSuccess}
                        handleChargedAmount={handleChargedAmount}
                        handleDisplay={handleDisplay}
                        isFormValid={
                          firstNameValid &&
                          lastNameValid &&
                          phoneValid &&
                          emailValid &&
                          priceValid
                        }
                        checkValidationSuper={() => {
                          validateForm();
                        }}
                      />
                    </Elements>
                  </Row>
                </Col>
              )}
              {rSelected === 2 && (
                <Col md={{ offset: 3, size: 6 }}>
                  <Row style={{ marginTop: "30px" }}>
                    <Cash
                      name={`${firstName}  ${lastName}`}
                      description={orderNote}
                      phone={phone}
                      email={email}
                      amount={price * 100}
                      firstName={firstName}
                      lastName={lastName}
                      price={price}
                      reference={reference}
                      handleSuccess={handleSuccess}
                      handleChargedAmount={handleChargedAmount}
                      handleDisplay={handleDisplay}
                      formIsValid={
                        firstNameValid &&
                        lastNameValid &&
                        phoneValid &&
                        emailValid &&
                        priceValid
                      }
                      checkValidationSuper={() => {
                        validateForm();
                      }}
                    />
                  </Row>
                </Col>
              )}
            </Row>
          </Container>

          <Container className={!success ? "displayNone" : ""}>
            <Row>
              <Col
                md={12}
                className="text-center"
                style={{ marginBottom: "20px" }}
              >
                <a href="https://real.dog/">
                  <img
                    src="https://realdog-s3.s3-us-west-1.amazonaws.com/rdblogo.gif"
                    width="80"
                    height="80"
                    alt="logo"
                  />
                </a>
              </Col>
            </Row>
            <Row>
              <Col md={{ offset: 3, size: 6 }} className="success-message">
                <h2>Success!</h2>
                <p>Thank you for your payment. ${chargedAmount} charged.</p>
                <br />
                <p>Note: {orderNote}</p>
                <Col md={{ offset: 3, size: 6 }}>
                  <Button href="." className="SubmitButton">
                    Pay Again
                  </Button>
                </Col>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <Container>
          <Row>
            <Col
              md={12}
              className="text-center"
              style={{ marginBottom: "20px" }}
            >
              <a href="https://real.dog/">
                <img
                  src="https://realdog-s3.s3-us-west-1.amazonaws.com/rdblogo.gif"
                  width="80"
                  height="80"
                  alt="logo"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Input
                  required
                  value={userPin}
                  type="password"
                  placeholder="Enter Password"
                  onChange={(e) => handlePin(e)}
                  style={{width:"300px",margin:"auto",marginTop:"20px"}}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default App;
