import { gql } from "@apollo/client";

const CHARGE = gql`
  mutation CreateCharge($input: ChargeInput) {
    createCharge(input: $input) {
      id
      status
      amount
    }
  }
`;

const CREATE_POS = gql`
  mutation CREATE_POS($input: inputPos) {
    createPos(input: $input) {
      id
    }
  }
`;
export { CHARGE, CREATE_POS };
