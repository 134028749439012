import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Container, Row, Col, Button, Spinner, Alert } from "reactstrap";
import { useMutation } from "@apollo/client";
import { CHARGE, CREATE_POS } from "../../requests";
import moment from "moment";
const CreditCard = ({
  name,
  phone,
  amount,
  email,
  firstName,
  lastName,
  description,
  reference,
  handleSuccess,
  handleChargedAmount,
  handleDisplay,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isComplete, setIsComplete] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [createCharge] = useMutation(CHARGE);
  const [createPos] = useMutation(CREATE_POS);

  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setOrderLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const chargeInput = {
      source: "",
      currency: "usd",
      amount: parseInt(amount), // need to fix this problem properly.
      description: description,
      metadata: {
        customerName: name,
        phone: phone,
        email: email,
        link: reference,
      },
    };

    console.log(chargeInput);
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, source } = await stripe.createSource(cardElement);
    if (error) {
      console.log("[error]", error);
      setAlertMessage(error.message);
      setVisible(true);
    } else {
      chargeInput.source = source.id;
      console.log("[Payment Source]", source);
    }
    console.log({ chargeInput });
    createCharge({
      variables: {
        input: chargeInput,
      },
    })
      .then((res) => {
        const posInput = {
          name: firstName,
          surname: lastName,
          phone_number: phone,
          email: email,
          charged_amount: amount,
          paid_method: "credit_card",
          reference: reference,
          note: description,
          created_at: moment().unix(),
        };
        createPos({
          variables: {
            input: posInput,
          },
        })
          .then(() => {
            if (res.data.createCharge.status === "succeeded") {
              handleSuccess(true);
              handleChargedAmount(res.data.createCharge.amount);
            }
            setOrderLoading(false);
          })
          .catch((err) => {
            setOrderLoading(false);
            console.log(err);
            setAlertMessage("Payment had a problem.");
            setVisible(true);
          });
      })
      .catch((err) => {
        setOrderLoading(false);
        console.log(err);
        setAlertMessage("Payment had a problem.");
        setVisible(true);
      });
  };

  const onChangeHandler = (event) => {
    setIsComplete(event.complete);
  };

  const handleConfirm = () => {
    setIsReady(true);
    handleDisplay(false);
  };
  return (
    <Col md={12}>
      <Alert color="warning" isOpen={visible} toggle={onDismiss}>
        {alertMessage}
      </Alert>
      <Row className={isReady ? "displayNone" : "credit-card-container"}>
        <Col>
          <CardElement
            options={{
              // classes: {
              //   base: "card-element",
              // },
              style: {
                base: {
                  iconColor: "#252525",
                  color: "#53595e",
                  fontWeight: 500,
                  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                  fontSize: "16px",
                  fontSmoothing: "antialiased",
                  ":-webkit-autofill": {
                    color: "#53595e",
                  },
                  "::placeholder": {
                    color: "#53595e",
                  },
                },
                invalid: {
                  iconColor: "#ffc7ee",
                  color: "#ffc7ee",
                },
              },
            }}
            onChange={(event) => onChangeHandler(event)}
          />
        </Col>

        <Col md={12}>
          <Button
            className="SubmitButton"
            disabled={!isComplete && stripe}
            onClick={handleConfirm}
          >
            CONFIRM
          </Button>
        </Col>
      </Row>

      <Row className={!isReady ? "displayNone" : "confirm-modal"}>
        <Col md={12} style={{ color: "#fff" }}>
          {name ? (
            <p>
              <span className="span-bold">Name:</span> {name}
            </p>
          ) : (
            ""
          )}
          {amount ? (
            <p>
              <span className="span-bold">Price:</span> ${amount * 0.01}{" "}
            </p>
          ) : (
            ""
          )}
          {phone ? (
            <p>
              <span className="span-bold">Phone:</span> {phone}
            </p>
          ) : (
            ""
          )}
          {email ? (
            <p>
              <span className="span-bold">Email:</span> {email}
            </p>
          ) : (
            ""
          )}
          {description ? (
            <p>
              <span className="span-bold">Note:</span> {description}
            </p>
          ) : (
            ""
          )}
          <p></p>
          <Button
            className="btn-confirm"
            disabled={!isComplete && stripe}
            onClick={handleSubmit}
          >
            {orderLoading ? <Spinner color="light"> </Spinner> : "CONFIRM"}
          </Button>

          <Button
            className="btn-cancel"
            onClick={() => {
              setIsReady(false);
              handleDisplay(true);
            }}
          >
            CANCEL
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default CreditCard;
