import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import Success from "./pages/success";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client/react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { Auth0Provider } from "@auth0/auth0-react";
// empty

const client = new ApolloClient({
  uri: process.env.REACT_APP_STRIPE_GRAPHQL,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.Fragment>
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <App {...props} href="Home Page" />}
          />
          <Route
            exact
            path="/north-park"
            render={(props) => <App {...props} href="North Park" />}
          />
          <Route
            exact
            path="/pacific-beach"
            render={(props) => <App {...props} href="Pacific Beach" />}
          />
          <Route
            path="*"
            render={(props) => <App {...props} href="Home Page" />}
          />
        </Switch>
      </Router>
    </ApolloProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
